import React, { useState } from "react";
import Slider from "react-slick";
import arrowBackIcon from "../assets/icons/eva_arrow-back-fill.svg";
import arrowNextIcon from "../assets/icons/eva_arrow-next-fill.svg";
import starsIcon from "../assets/icons/stars.svg";
import peopleIcon from "../assets/icons/people.svg";

const TestimoniEN = ({
  listTestimoni = [
    {
      name: "Metin Turhan",
      image: peopleIcon,
      city: "Bursa",
      country: "Turkiye",
      rating: "4.5",
      testimoni:
        "İşletmemde verdiğim hizmet ağını genişletmek için çalıştığım İstiklal Network ile çok iyi sonuçlar aldık ve uzun yıllardır birlikte çalışmaya devam ediyoruz.",
    },
    {
      name: "İlhan Carboğan",
      image: peopleIcon,
      city: "Berlin",
      country: "Almanya",
      rating: "4.5",
      testimoni:
        "İnşaat şirketimiz için bir internet sitesine ihtiyacımız vardı ve bu problemimizi İstiklal Network ile çözdüğümüz için çok memnunuz.",
    },
    {
      name: "Turhan Yavuz",
      image: peopleIcon,
      city: "İzmir",
      country: "Türkiye",
      rating: "4.5",
      testimoni:
        "Halı yıkama sektöründe olan firmamız için yaptıkları sıkı çalışmadan dolayı İstiklal Network'e teşekkür ediyorum.",
    },
    {
      name: "Mesut Aydemir",
      image: peopleIcon,
      city: "Antalya",
      country: "Türkiye",
      rating: "4.5",
      testimoni:
        "Alanya'da yaptığımız evden eve nakliyat sektöründe zirvede olduğumuz gibi bizi internettede zirveye taşıyan İstiklal Network ile çalışmaktan çok memnunuz.",
    },
  ],
}) => {
  const settings = {
    dots: true,
    customPaging: function (i) {
      return (
        <a href="/#" className="">
          <span className="mx-2 rounded-l-full rounded-r-full h-4 w-4 block cursor-pointer transition-all"></span>
        </a>
      );
    },
    dotsClass: "slick-dots w-max absolute mt-96",
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 770,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [sliderRef, setSliderRef] = useState(null);

  return (
    <>
      <Slider
        {...settings}
        arrows={false}
        ref={setSliderRef}
        className="flex items-stretch justify-items-stretch"
      >
        {listTestimoni.map((listTestimonis, index) => (
          <div className="px-3 flex items-stretch" key={index}>
            <div className="border-2 border-gray-300 hover:border-brand transition-all rounded-lg p-8 flex flex-col">
              <div className="flex flex-col xl:flex-row w-full items-stretch xl:items-center">
                <div className="flex order-2 xl:order-1">
                  <img
                    className="h-12 w-12"
                    src={listTestimonis.image}
                    alt="Icon People"
                  />
                  <div className="flex flex-col ml-5 text-left">
                    <p className="text-lg text-gray-600 dark:text-gray-300 capitalize">
                      {listTestimonis.name}
                    </p>
                    <p className="text-sm text-gray-500 dark:text-gray-200 capitalize">
                      {listTestimonis.city},{listTestimonis.country}
                    </p>
                  </div>
                </div>
                <div className="flex flex-none items-center ml-auto order-1 xl:order-2">
                  <p className="text-sm dark:text-gray-300">
                    {listTestimonis.rating}
                  </p>
                  <span className="flex ml-4">
                    <img className="h-6 w-6" src={starsIcon} alt="Stars Icon" />
                  </span>
                </div>
              </div>
              <p className="mt-5 text-left dark:text-gray-300">
                “{listTestimonis.testimoni}”.
              </p>
            </div>
          </div>
        ))}
      </Slider>
      <div className="flex w-full items-center justify-end">
        <div className="flex flex-none justify-between w-auto mt-14">
          <div
            className="mx-4 flex items-center justify-center h-14 w-14 rounded-full bg-white border-brand border hover:bg-brand hover:text-white-500 transition-all text-brand cursor-pointer"
            onClick={sliderRef?.slickPrev}
          >
            <img
              className="h-6 w-6"
              src={arrowBackIcon}
              alt="Arrow Back Icon"
            />
          </div>
          <div
            className="flex items-center justify-center h-14 w-14 rounded-full bg-white border-brand border hover:bg-brand hover:text-white-500 transition-all text-brand cursor-pointer"
            onClick={sliderRef?.slickNext}
          >
            <img
              className="h-6 w-6"
              src={arrowNextIcon}
              alt="Arrow Next Icon"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TestimoniEN;
