import { Link } from "gatsby";
import React from "react";
import ButtonPrimary from "./misc/ButtonPrimary";
import TestimoniEN from "./TestimoniEN";

const PricingEN = () => {
  return (
    <div className="bg-gradient-to-b from-gray-50 to-gray-100 dark:from-gray-800 dark:to-gray-900 w-full py-14">
      <div className="max-w-screen-xl px-6 sm:px-8 lg:px-16 mx-auto flex flex-col w-full text-center justify-center">
        {/* <div className="flex flex-col w-full">
          <h3 className="text-2xl sm:text-3xl lg:text-4xl font-medium text-gray-600 dark:text-gray-300 leading-relaxed">
            Choose Your Plan
          </h3>
          <p className="leading-normal w-10/12 sm:w-7/12 lg:w-6/12 mx-auto my-2 text-center dark:text-gray-300">
            Let's choose the package that is best for you and explore it happily
            and cheerfully.
          </p>
          <div className="grid grid-flow-row sm:grid-flow-col grid-cols-1 sm:grid-cols-3 gap-4 lg:gap-12 py-8 lg:py-12 px-6 sm:px-0 lg:px-6">
            <div className="flex flex-col justify-center items-center border-2 border-gray-300 rounded-xl py-4 px-6 lg:px-12 xl:px-20">
              <div className="p-4 lg:p-0 mt-6 lg:mt-16">
                <StaticImage
                  src="../assets/images/free.png"
                  alt="VPN Illustrasi"
                  height={165}
                  width={145}
                />
              </div>
              <p className="text-lg text-gray-600 font-medium capitalize my-2 sm:my-7 dark:text-gray-300">
                Free Plan
              </p>
              <ul className="flex flex-col list-inside pl-6 xl:pl-0 items-start justify-start text-left text-gray-500 dark:text-gray-200 flex-grow">
                <li className="relative check custom-list my-2">
                  Unlimited Bandwitch
                </li>
                <li className="relative check custom-list my-2">
                  Encrypted Connection
                </li>
                <li className="relative check custom-list my-2">
                  No Traffic Logs
                </li>
                <li className="relative check custom-list my-2">
                  Works on All Devices
                </li>
              </ul>
              <div className="flex flex-col w-full justify-center mb-8 flex-none mt-12">
                <p className="text-2xl text-gray-600 dark:text-gray-300 text-center mb-4">
                  Free
                </p>
                <ButtonOutline>Select</ButtonOutline>
              </div>
            </div>
            <div className="flex flex-col justify-center items-center border-2 border-gray-300 rounded-xl py-4 px-6 lg:px-12 xl:px-20">
              <div className="p-4 lg:p-0 mt-6 lg:mt-16">
                <StaticImage
                  src="../assets/images/standart.png"
                  alt="VPN Illustrasi"
                  height={165}
                  width={145}
                />
              </div>
              <p className="text-lg text-gray-600 dark:text-gray-300 font-medium capitalize my-2 sm:my-7">
                Standart Plan
              </p>
              <ul className="flex flex-col list-inside pl-6 xl:pl-0 items-start justify-start text-left text-gray-500 dark:text-gray-200 flex-grow">
                <li className="relative check custom-list my-2">
                  Unlimited Bandwitch
                </li>
                <li className="relative check custom-list my-2">
                  Encrypted Connection
                </li>
                <li className="relative check custom-list my-2">
                  No Traffic Logs
                </li>
                <li className="relative check custom-list my-2">
                  Works on All Devices
                </li>
              </ul>
              <div className="flex flex-col w-full justify-center mb-8 flex-none mt-12">
                <p className="text-2xl text-gray-600 dark:text-gray-300 text-center mb-4">
                  Standart
                </p>
                <ButtonOutline>Select</ButtonOutline>
              </div>
            </div>
            <div className="flex flex-col justify-center items-center border-2 border-gray-300 rounded-xl py-4 px-6 lg:px-12 xl:px-20">
              <div className="p-4 lg:p-0 mt-6 lg:mt-16">
                <StaticImage
                  src="../assets/images/premium.png"
                  alt="VPN Illustrasi"
                  height={165}
                  width={145}
                />
              </div>
              <p className="text-lg text-gray-600 dark:text-gray-300 font-medium capitalize my-2 sm:my-7">
                Premium Plan
              </p>
              <ul className="flex flex-col list-inside pl-6 xl:pl-0 items-start justify-start text-left text-gray-500 dark:text-gray-200 flex-grow">
                <li className="relative check custom-list my-2">
                  Unlimited Bandwitch
                </li>
                <li className="relative check custom-list my-2">
                  Encrypted Connection
                </li>
                <li className="relative check custom-list my-2">
                  No Traffic Logs
                </li>
                <li className="relative check custom-list my-2">
                  Works on All Devices
                </li>
              </ul>
              <div className="flex flex-col w-full justify-center mb-8 flex-none mt-12">
                <p className="text-2xl text-gray-600 dark:text-gray-300 text-center mb-4">
                  Premium
                </p>
                <ButtonOutline>Select</ButtonOutline>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="flex flex-col w-full my-16">
          <h3 className="text-2xl sm:text-3xl lg:text-4xl font-medium text-gray-600 dark:text-gray-300 leading-relaxed w-9/12 sm:w-6/12 lg:w-4/12 mx-auto">
            Huge Global Network of Fast VPN
          </h3>
          <p className="leading-normal mx-auto my-2 w-10/12 sm:w-7/12 lg:w-6/12 dark:text-gray-300">
            See Istiklal Network everywhere to make it easier for you when you
            move locations.
          </p>
          <div className="py-12 w-full px-8 mt-16">
            <img src={hugeGlobal} alt="Huge Global" />
          </div>
          <div className="w-full flex justify-evenly items-center mt-4 flex-wrap lg:flex-nowrap">
            <img
              className="h-14 w-auto mt-4 lg:mt-2"
              src={netflixImg}
              alt="Netflix"
            />
            <img
              className="h-14 w-auto mt-4 lg:mt-2"
              src={redditImg}
              alt="Reddit"
            />
            <img
              className="h-14 w-auto mt-4 lg:mt-2"
              src={amazonImg}
              alt="Amazon"
            />
            <img
              className="h-14 w-auto mt-4 lg:mt-2"
              src={discordImg}
              alt="Discord"
            />
            <img
              className="h-14 w-auto mt-4 lg:mt-2"
              src={spotifyImg}
              alt="Spotify"
            />
          </div>
        </div> */}
        <div className="flex flex-col w-full my-16">
          <h3 className="text-2xl sm:text-3xl lg:text-4xl font-medium text-gray-600 dark:text-gray-300 leading-normal w-9/12 lg:w-4/12 mx-auto">
            Happy Customers Working With Us
          </h3>
          <p className="leading-normal mx-auto mb-2 mt-4 w-10/12 sm:w-7/12 lg:w-6/12 dark:text-gray-300">
            These are the stories of our customers who have worked with us.
          </p>
          <div className="w-full flex flex-col py-12">
            <TestimoniEN />
          </div>
          <div className="relative w-full mt-16">
            <div className="absolute rounded-xl py-8 sm:py-14 px-6 sm:px-12 lg:px-16 w-full flex flex-col sm:flex-row justify-between items-center z-10 bg-gray-50 dark:bg-gray-800">
              <div className="flex flex-col text-left w-10/12 sm:w-7/12 lg:w-5/12 mb-6 sm:mb-0">
                <h3 className="text-gray-600 dark:text-gray-200 text-xl sm:text-2xl lg:text-3xl leading-relaxed font-medium">
                  Work with us
                  <br /> Get Special Offers!
                </h3>
                <p className="dark:text-gray-300">
                  You can send us message what you have in your mind.
                </p>
              </div>
              <Link to="/en/contact">
                <ButtonPrimary>Contact Us</ButtonPrimary>
              </Link>
            </div>
            <div
              className="absolute bg-gray-600 opacity-5 w-11/12 rounded-lg h-60 sm:h-56 top-0 mt-8 mx-auto left-0 right-0"
              style={{ filter: "blur(114px)" }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingEN;
